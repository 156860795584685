body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #eee;
}

* {
  box-sizing: border-box;
}

body, html {
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
}

h1 { font-family: 'Raleway', sans-serif; }
h2 { color: #000 !important; }

h2 {
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 30px;
  margin: 20px 0;
  padding: 0;
}
.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}
label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}
form {
  margin-bottom: 40px;
}
.StripeElement {
  display: block;
  margin: 30px 0 40px;
  max-width: 500px;
  padding: 10px 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
}
.StripeElement.PaymentRequestButton {
  padding: 0;
}