
.imagedim {
  filter:grayscale(10%);
  margin-bottom: -4px;
}

a {
  text-decoration: none;
}
/* unvisited link */
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
a:active {
  color: black;
}